"use strict";

import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import LogRocket from 'logrocket';

if (process.env.NODE_ENV === 'production') {
  LogRocket.init(window.rPlus.logRocket);
  Sentry.init({
    dsn: window.rPlus.sentry,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    logErrors: true,
  });
}

require('bootstrap');
require('select2');
require('bootstrap-datepicker');
require('bootstrap-datepicker/js/locales/bootstrap-datepicker.fr');
require('bootstrap-datepicker/js/locales/bootstrap-datepicker.en-GB');
require('bootstrap-datepicker/js/locales/bootstrap-datepicker.nl-BE');
require('uikit');
require('../pages/js/pages');

import 'es6-promise/auto';
import 'isomorphic-fetch';
import App from './common/_app';
import Comment from './common/_comments';
import DataTables from './common/_datatables';
import Feedback from "./common/_feedback";
import Form from "./common/_form";
import Notification from'./common/_notifications';
import Prestation from "./common/_prestations";
import Search from "./common/_search";
import Task from "./common/_tasks";
import User from "./common/_users";

const rPlus = {
  showTempSidebar(id, type, fetch = true) {
    var sidebarTemp = $('#rp-sidebar');
    var sidebarOverlay = $('.rp-sidebar-overlay');

    if(type === 'add_report'){
       sidebarTemp = $('#rp-sidebar-add-report');
       sidebarOverlay = $('.rp-sidebar-overlay-add-report');
    } else {
      //const sidebarTemp = $('#sidebar-temp-overlay');
       sidebarTemp = $('#rp-sidebar');
       sidebarOverlay = $('.rp-sidebar-overlay');
    }

    if (sidebarTemp.hasClass("is-closed")) {
      sidebarTemp.addClass("is-open");
      sidebarOverlay.show();

      sidebarTemp.removeClass("is-closed");

      if (fetch && type !== 'add_report') {
        Comment.fetchCommentsFrom(id, type, App.currentUser.isManager);
      }

      sidebarTemp.data(`${type}-id`, id);

      return;
    }

    sidebarTemp.removeClass("is-open");
    sidebarTemp.addClass("is-closed");
    sidebarOverlay.hide();
  },

  initTempSidebar() {
    $(document).on('click', '.addCommentLink, .rp-sidebar-close a, .rp-sidebar-overlay, .rp-sidebar-overlay-add-report, #closeTempSideBar, .mask', function () {
      let type = $(this).data('report-id') ? 'report' : 'task';

      if ($(this).hasClass('add-report-sidebar')) {
        type = 'add_report';
      }

      const id = $(this).data(`${type}-id`);

      Comment.initWysiwygComment($(this).data('project-id'), parseInt($(this).data('project-ended')) === 1);
      rPlus.showTempSidebar(id, type);
    });

    const $commentsChannelSwitch = $('#comments-channel-switch');
    if ($commentsChannelSwitch.length > 0) {
      $commentsChannelSwitch.on('click', 'a', (e) => {
        const $sideBar = $('#rp-sidebar');
        const type = $sideBar.data('report-id') ? 'report' : 'task';
        const id = $sideBar.data(`${type}-id`);

        Comment.fetchCommentsFrom(id, type, e.target.id === 'private-channel');
      });
    }
  },

  highlightElement(id) {
    const element = document.getElementById(id);
    element.scrollIntoView(false);
    $(element).addClass('animate-highlight');
  },

  checkQueryParamsForSomethingToShow() {
    const queryParams = new URLSearchParams(document.location.search);

    if (queryParams.has('comment')) {
      if (!queryParams.has('task') && !queryParams.has('report')) {
        return false;
      }

      const type = queryParams.has('report') ? 'report' : 'task';
      const id = queryParams.get(type);
      const comment = queryParams.get('comment');
      const privateChannel = queryParams.get('private');

      this.showTempSidebar(id, type, false);
      Comment.fetchCommentsFrom(id, type, privateChannel).done(() => {
        this.highlightElement(`comment-${comment}`);
      });
    } else if (queryParams.has('task')) {
      setTimeout(() => {
        this.highlightElement(queryParams.get('task'));
      }, 1000);
    } else if (queryParams.has('job')) {
      this.highlightElement(`job-${queryParams.get('job')}`);
    } else if (queryParams.has('construction-task')) {
      setTimeout(() => {
        this.highlightElement(`construction-task-${queryParams.get('construction-task')}`);
      }, 1000);
    } else if (queryParams.has('job-task')) {
      $(`#job-task-${queryParams.get('job-task')}`).closest('.collapse').collapse('show');
      setTimeout(() => {
        this.highlightElement(`job-task-${queryParams.get('job-task')}`);
      }, 1000);
    } else if (queryParams.has('report')) {
      setTimeout(() => {
        this.highlightElement(queryParams.get('report'));
      }, 1000);
    } else if (queryParams.has('document')) {
      $(`#document-${queryParams.get('document')}`).closest('.rp-document-folder').addClass('uk-open').find('.uk-accordion-content').removeAttr('hidden');
      this.highlightElement(`document-${queryParams.get('document')}`);
    } else if (queryParams.has('section')) {
      this.highlightElement(`report-section-${queryParams.get('section')}`);
    } else if (queryParams.has('observation')) {
      const element = document.getElementById(`report-observation-${queryParams.get('observation')}`);
      element.scrollIntoView(false);
      $(element).closest('tr').addClass('animate-highlight')
    } else if (queryParams.has('user')) {
      setTimeout(() => {
        this.highlightElement(queryParams.get('user'));
      }, 1000);
    }

    return false;
  },
  getTinyColor() {
    //console.log('test:' + color.isLight());
    //var color = tinycolor("white");
    var getEnvironmentColor = $('body').attr('rp-env-color');
    if(tinycolor(getEnvironmentColor).isLight()){
      $('body').addClass('rp-env-light');
    } else {
      $('body').addClass('rp-env-dark');
    }

  },

  init() {
    this.initTempSidebar();

    this.checkQueryParamsForSomethingToShow();
    this.getTinyColor();

    Comment.init();
    DataTables.init();
    Form.init();
    Notification.init();
    Prestation.init();
    Search.init();
    Task.init();
    User.init();

    if (App.currentUser && App.currentUser.isManager) {
      Feedback.init();
    }
  },
};

$(function () {
  rPlus.init();
});

export default rPlus;
