"use strict";

import App from "./_app";
import Helper from "./_helpers";

const Notification = {
  getNotificationTemplate(notification) {
    return `<li class="alert-list">
        <a href="${Routing.generate('notification_see', {id: notification.id})}" class="p-t-15 p-b-15 align-items-center" data-navigate="view" data-view-animation="push-parrallax">
            <p class="toggle-notification-read-state" data-href="${Routing.generate('notification_mark_as_read_unread', {id: notification.id, isRead: notification.isRead ? 0 : 1})}">
                <i class="fa fa-circle${!notification.isRead ? '-o' : ''}"></i>
            </p>
            <div class="col fs-12 p-l-10">
                <span class="text-master overflow-ellipsis link">${notification.date}, ${notification.title}<br></span>
                <div class="text-master overflow-ellipsis">${notification.body}</div>
            </div>
        </a>
    </li>`;
  },

  getNotificationSectionTemplate(sectionNotifications, sectionTitle) {
    let notifications = [];
    sectionNotifications.forEach((notification) => notifications.push(this.getNotificationTemplate(notification)));

    return `<div class="list-view-group-container">
        <div class="list-view-group-header">
            ${sectionTitle}
        </div>
        <ul>
            ${notifications.join('')}
        </ul>
    </div>`;
  },

  $icon: null,
  $iconBubble: null,
  init() {
    const $container = $('#notifications-container');

    if ($container.length === 0) {
      return;
    }

    this.$icon = $('#notification-icon');
    this.$iconBubble = $('#notification-bubble');
    const $markAllRead = $('#notification-mark-all-as-read');
    const headers = {'Accept': 'application/json'};

    this.$iconBubble.hide();
    $markAllRead.hide();

    const reloadNotifications = () => {
      $container.empty().append(App.$loader);
      fetch(Routing.generate('notification_unread'), {
        method: 'GET',
        headers: headers
      })
        .then(response => Helper.handleResponse(response, reloadNotifications))
        .then((data) => {
          App.$loader.remove();

          if (data.notifications.length === 0) {
            $markAllRead.hide();
            $container.append(`<p style="padding: 10px">${Translator.trans('notification.no-unread')}</p>`);
            return;
          }

          $markAllRead.show();
          for (const [sectionTitle, sectionNotifications] of Object.entries(data.notifications)) {
            $container.append(this.getNotificationSectionTemplate(sectionNotifications, sectionTitle));
          }
        });
    }

    this.$icon.on('click.pg.quickview.data-api touchstart', (e) => {
      e.preventDefault();

      reloadNotifications();
    });

    $container.on('click', '.toggle-notification-read-state', (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();

      const $this = $(e.currentTarget);
      const $icon = $this.find('i');

      fetch($this.data('href'), {
        method: 'PATCH',
        headers: headers
      })
        .then(response => {
          if (response.ok && response.status === 204) {
            let currentUrl = $this.data('href');
            const state = parseInt(currentUrl.substr(-1));

            if (state === 0) {
              $icon
                .removeClass('fa-circle')
                .addClass('fa-circle-o');
            } else {
              $icon
                .addClass('fa-circle')
                .removeClass('fa-circle-o');
            }

            $this.data('href', currentUrl.slice(0, -1) + (state === 0 ? '1' : '0'));
            this.updateBubbleCount();

            return;
          }

          return Helper.handleResponse(response);
        });
    });

    $markAllRead.click((e) => {
      e.preventDefault();

      $container.empty().append(App.$loader);
      fetch(Routing.generate('notification_mark_all_as_read'), {
        method: 'PATCH',
        headers: headers
      })
        .then(response => {
          App.$loader.remove();

          if (response.ok && response.status === 204) {
            reloadNotifications();
            this.updateBubbleCount();

            return;
          }

          return Helper.handleResponse(response);
        });
    });

    this.updateBubbleCount();
    this.startBubbleCountUpdater();
  },

  bubbleCountInterval: null,
  startBubbleCountUpdater() {
    this.bubbleCountInterval = setInterval(() => this.updateBubbleCount(), 30000);
  },

  updateBubbleCount() {
    fetch(Routing.generate('notification_check_unread'), {
        method: 'GET',
        headers: {'Accept': 'application/json'}
      })
      .then(response => Helper.handleResponse(response, () => this.startBubbleCountUpdater()))
      .then((data) => {
        this.$iconBubble.text(data.unread);

        if (data.unread > 0) {
          this.$iconBubble.show();
          this.$icon.addClass('swing');
        } else {
          this.$iconBubble.hide();
          this.$icon.removeClass('swing');
        }
      })
      .catch(() => clearInterval(this.bubbleCountInterval));
  },
};

export default Notification;
