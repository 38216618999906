"use strict";

import App from "./_app";
import Helper from "./_helpers";
import heic2any from "heic2any";

const Form = {
  initDate(dateRanges, options = {}) {
    if (dateRanges === undefined) {
      dateRanges = $('.datepicker-range');
    }

    if (dateRanges.length > 0) {
      dateRanges.datepicker({
        language: App.locale,
        format: 'dd/mm/yyyy',
        autoclose: true,
        todayBtn: 'linked',
        todayHighlight: true,
        ...options
      });
    }
  },

  initDropzones() {
    const $dropzones = $('.dropzone');
    if ($dropzones.length > 0) {
      $dropzones.click(e => $(e.target).find('input[type=file]').click());
    }
  },

  initSelect2($selects, contextOptions = {}, destroyIfExists = true) {
    $selects.each(function() {
      const $select = $(this);
      const options = {};

      if (destroyIfExists && $select.data('select2')) {
        $select.select2('destroy');

        if (contextOptions.data) {
          $select.empty();
        }
      }

      if ($select.prop('multiple')) {
        options.allowClear = true;
        options.closeOnSelect = false;

        if (!contextOptions.placeholder && !$select.data('placeholder') && !$select.attr('placeholder')) {
          options.placeholder = '   ';
        }
      }

      if ($select.data('src')) {
        const limit = 10;

        options.ajax = {
          url: $select.data('src'),
          dataType: 'json',
          delay: 250,
          data: function (params) {
            return {
              q: params.term, // search term
              page: params.page,
              limit: limit,
            };
          },
          processResults: function (data, params) {
            // parse the results into the format expected by Select2
            // since we are using custom formatting functions we do not need to
            // alter the remote JSON data, except to indicate that infinite
            // scrolling can be used
            params.page = params.page || 1;

            return {
              results: data.items,
              pagination: {
                more: (params.page * limit) < data.total
              }
            };
          },
          cache: false
        };
      }

      $select.select2({
        minimumResultsForSearch: ($select.attr('data-disable-search') === 'true' ? -1 : 1),
        ...options,
        ...contextOptions,
      });
    });
  },

  initCKEditor($element, destroyIfExists = true, options = {}) {
    const element = $element.get(0);
    if (CKEDITOR.instances[element.id]) {
      if (!destroyIfExists) {
        return;
      }

      CKEDITOR.instances[element.id].destroy();
    }

    const editor = CKEDITOR.replace(element, {
      toolbar: [{
        name: 'basicstyles',
        items: ['Bold', 'Italic', 'Underline', 'Strike', 'Link', 'BulletedList', 'NumberedList', 'Blockquote', '-', 'Undo', 'Redo']
      }],

      ...options
    });

    editor.on('change', () => {
      editor.updateElement();
      $element.trigger('change');
    });
  },

  initCustomFileInput($customFileInput, failureCallback, successCallback, convertHeic = false) {
    if ($customFileInput === undefined) {
      $customFileInput = $('.custom-file-input');
    }

    if ($customFileInput.length) {
      $customFileInput.closest('.vich-file').hide();
      $customFileInput.change((e) => {
        const $documentImagePreview = $(e.target).closest('.documentUploadWrapper').find('.image-preview');
        const $documentFileName = $(e.target).closest('.documentUploadWrapper').find('.documentFileName');
        $documentFileName.empty();
        $documentImagePreview.attr('src', '').hide();

        if (e.target.files.length === 0) {
          if (failureCallback !== undefined) {
            failureCallback();
          }

          return;
        }

        const totalFiles = e.target.files.length;
        const multipleFilesSelected = totalFiles > 1;

        e.target.files.forEach((documentFile) => {
          let dataUriPromise;
          let fileName = documentFile.name;

          if (convertHeic && (documentFile.type === 'image/heic' || fileName.toLowerCase().endsWith('.heic') || fileName.toLowerCase().endsWith('.HEIC'))) {
            fileName = fileName.replace(/heic/i, "jpeg");
            dataUriPromise = heic2any({
                blob: documentFile,
                toType: "image/jpeg",
              })
              .then((jpegBlob) => Helper.blobToDataURI(jpegBlob));
          } else {
            dataUriPromise = Helper.blobToDataURI(documentFile);
          }

          dataUriPromise.then((documentFileURI) => {
            if (successCallback !== undefined) {
              successCallback(documentFile, documentFileURI, totalFiles);

              return;
            }

            let documentType = '';

            if (documentFile.type === 'image/jpeg' || documentFile.type === 'image/png' || documentFile.type === 'image/gif' || documentFile.type === 'image/heic') {
              documentType = '-image';
            } else if (documentFile.type === 'application/pdf') {
              documentType = '-pdf';
            } else if (documentFile.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
              documentType = '-word';
            } else if (documentFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
              documentType = '-excel';
            }

            if ($documentFileName.length > 0) {
              $documentFileName.append("<br><i class='fa fa-file" + documentType + "-o'></i> " + fileName);
            }

            if ($documentImagePreview.length > 0 && documentType === "-image" && !multipleFilesSelected) {
              $documentImagePreview.attr('src', documentFileURI).attr('uk-tooltip', fileName).show();
            }
          });
        });
      });
    }
  },

  initDocumentUpload() {
    $('.image-preview').filter('[src=""]').hide();

    $('.documentUploadWrapper').closest('form').submit(function() {
      $(this).append(App.$loader);
    });

    $('.documentUploadButton').on('click', function (e) {
      $(this).closest('.documentUploadWrapper').find('input[type=file]').click();
    });
  },

  initPassword($element) {
    if ($element === undefined) {
      $element = $('input[type=password]');
    }

    $element.each(function() {
      const $passwordField = $(this);
      const $parent = $passwordField.parent();
      const $group = $('<div class="input-group"></div>');
      const $groupAppend = $('<div class="input-group-addon"></div>');
      const $icon = $('<a href="javascript:void(0);" class="input-group-text"><i class="fa fa-eye"></i></a>');

      $passwordField.remove();
      $groupAppend.append($icon);
      $group
        .append($passwordField)
        .append($groupAppend);

      $parent.append($group);

      $icon.click(() => {
        if (this.type === 'password') {
          this.type = 'text';
          $icon.children('i').removeClass('fa-eye').addClass('fa-eye-slash');
        } else {
          this.type = 'password';
          $icon.children('i').addClass('fa-eye').removeClass('fa-eye-slash');
        }
      });
    });
  },

  init() {
    this.initDate();
    this.initDropzones();
    this.initCustomFileInput();
    this.initPassword();
    this.initDocumentUpload();
  },
};

export default Form;
