"use strict";

import Helper from "./_helpers";
import App from "./_app";

const Comment = {
  getNoCommentTemplate(type) {
    return `<div class="rp-comment-empty"><i class="fa fa-comment"></i><br>${Translator.trans('comments.no-comment-' + type)}</div>`;
  },

  getTaskHtmlTemplate(task) {
    const date = Helper.formatDateLong(new Date(task.dateCreated.date.replace(/ /g,"T")));

    return '<div class="rp-comment-notification">' +
      '<div class="rp-comment-notification-icon">' +
      '<i class="fa fa-check-circle-o"></i>' +
      '</div>' +
      Translator.trans('comments.tasks.content', {'createdby_name': task.createdBy.name, 'task_name': task.name, 'assignedto_name': task.assignedTo ? task.assignedTo.name : Translator.trans('comments.tasks.not-assigned')}) +
      '<div class="rp-comment-metas">' + date + '</div>' +
      '</div>';
  },

  getDocumentHtmlTemplate(document) {
    const date = Helper.formatDateLong(new Date(document.dateCreated.date.replace(/ /g,"T")));
    const isImage = document.name.endsWith('png') || document.name.endsWith('jpg') || document.name.endsWith('jpeg');

    return '<div class="rp-comment-notification">' +
      '<div class="rp-comment-notification-icon">' +
      '<i class="fa fa-file' + (isImage ? '-image' : '') + '-o"></i>' +
      '</div>' +
      Translator.trans('comments.document.content', {'createdby_name': document.createdBy.name, 'document_link': document.link, 'document_name': document.name}) +
      (isImage ? '<img class="m-t-10 m-b-10" src="' + document.link + '?height=600" style="display: block; height: 300px;" />' : '') +
      '<div class="rp-comment-metas">' + date + '</div>' +
      '</div>';
  },

  getCommentHtmlTemplate(commentObj) {
    const isYou = commentObj.user.id === App.currentUser.id;
    const date = Helper.formatDateLong(new Date(commentObj.dateCreated.date.replace(/ /g,"T")));

    let comment = '';

    if (commentObj.content) {
      comment += '<div id="comment-' + commentObj.id + '" class="rp-comment ' + (isYou ? 'is-you' : '' ) + '">' +
        '<div class="rp-comment-wrapper">' +
        '<div class="user-pic">' +
        '<img alt="Profile Image" width="40" height="40" data-src-retina="'+ commentObj.user.avatar +'" data-src="'+ commentObj.user.avatar +'" src="'+ commentObj.user.avatar +'">' +
        '</div>' +
        '<div class="rp-comment-header">' +
        '<div class="rp-comment-user-name">' + (isYou ? Translator.trans('comments.you') : commentObj.user.name ) + ' <small>(' + commentObj.user.role + ')</small></div>' +
        (App.currentUser.isManager ? '<div class="rp-comment-actions">' +
          '<a href="' + Routing.generate('task_new_from_document', {'comment': commentObj.id}) + '" class="btn-add-todo ajax-task-btn" data-route="task_new_from_document" data-comment="' + commentObj.id + '"><i class="fa fa-check-circle-o"></i> ' + Translator.trans('comments.add-task') + '</a>' +
          '</div>' : '') +
        '</div>' +
        '<div class="rp-comment-content">' + commentObj.content + '</div>' +
        '<div class="rp-comment-metas">' + date + '</div>' +
        '</div>' +
        '</div>';
    }

    let attachments = [];

    if (commentObj.tasks) {
      attachments = commentObj.tasks.map((task) => {
        task.date = new Date(task.dateCreated.date.replace(/ /g,"T"));
        task.type = 'task';

        return task;
      });
    }

    if (commentObj.document) {
      commentObj.document.date = new Date(commentObj.document.dateCreated.date.replace(/ /g,"T"));
      commentObj.document.type = 'document';

      attachments.push(commentObj.document);
    }

    attachments.sort((a, b) => a.date - b.date);

    attachments.forEach((attachment) => {
      switch (attachment.type) {
        case 'task':
          comment += this.getTaskHtmlTemplate(attachment);
          break;

        case 'document':
          comment += this.getDocumentHtmlTemplate(attachment);
          break;
      }
    });

    return comment;
  },

  getWysiwygCommentConfig(projectId) {
    if (projectId === undefined) {
      projectId = App.currentProject;
    }

    return {
      extraPlugins: 'mentions',
      removePlugins: 'a11yhelp',
      mentions: [{
        feed: (query, callback) => {
          fetch(Routing.generate('project_users', {project: projectId || App.currentProject}) + '?q=' + encodeURIComponent(query.query))
            .then((response) => Helper.handleResponse(response))
            .then((data) => callback(data));
        },
        itemTemplate: '<li data-id="{id}"><strong class="name">{name}</strong> <span class="type">({type})</span></li>',
        outputTemplate: '<a href="user-mention:{id}">{name}</a><span>&nbsp;</span>',
        minChars: 0,
        marker: '@',
        caseSensitive: false
      }],
      toolbar: [{
        name: 'basicstyles',
        items: ['Bold', 'Italic', 'Underline', 'Strike', 'Link', 'BulletedList', 'NumberedList', 'Blockquote', '-', 'Undo', 'Redo']
      }],
    };
  },

  initWysiwygComment(projectId, ended) {
    if (CKEDITOR.instances.wysiwygcomment !== undefined) {
      CKEDITOR.instances.wysiwygcomment.setData("");
      CKEDITOR.instances.wysiwygcomment.destroy();
    }

    if (!document.getElementById('wysiwygcomment')) {
      return;
    }

    if (!ended) {
      $('#rp-comments_add').show();
      CKEDITOR.replace('wysiwygcomment', this.getWysiwygCommentConfig(projectId));
    } else {
      $('#rp-comments_add').hide();
    }
  },

  reloadComments(id, type, privateChannel, cleanForm = true) {
    const $sideBar = $('#rp-sidebar');

    if (!type) {
      type = $sideBar.data('report-id') ? 'report' : 'task';
    }

    if (!id) {
      id = $sideBar.data(`${type}-id`);
    }

    if (!privateChannel) {
      privateChannel = $('#comments-channel-switch').length > 0 && $('#private-channel').parent().hasClass('uk-active');
    }

    if (cleanForm) {
      CKEDITOR.instances.wysiwygcomment.setData('');
      $sideBar.find('.documentFileName').html('');
      document.getElementById('comment-file').value = '';
      $('#add-comment-action').children('button').prop('disabled', false);
      App.$loader.remove();
    }

    this.fetchCommentsFrom(id, type, privateChannel);
  },

  fetchCommentsFrom(id, type, privateChannel) {
    $("#wysiwygcomment").empty();
    $("#rp-comments_list").empty();

    if ($('#comments-channel-switch').length > 0) {
      const $privateParent = $('#private-channel').parent();
      const $publicParent = $('#public-channel').parent();
      if (privateChannel) {
        $privateParent.addClass('uk-active');
        $publicParent.removeClass('uk-active');
      } else {
        $privateParent.removeClass('uk-active');
        $publicParent.addClass('uk-active');
      }
    }

    return $.ajax({
      url: Routing.generate('comment_fetch'),
      type: "POST",
      dataType: "text json",
      data: {
        'id': id,
        'type': type,
        'private': privateChannel ? 1 : 0,
      },
    })
      .done((data, textStatus, jqXHR) => {
        Helper.handleResponse(data, () => this.fetchCommentsFrom(id, type, privateChannel));

        let commentCount = 0;
        let notSeenCommentsCount = 0;

        if (data.comments.length === 0) {
          $("#rp-comments_list").append(this.getNoCommentTemplate(type));
        } else {
          data.comments.forEach((comment) => {
            if (comment.content || comment.document) {
              ++commentCount;
            }

            $("#rp-comments_list").append(this.getCommentHtmlTemplate(comment));
          });
        }

        $('#rp-comments-subject').text(data.subject || '');

        if (data.counts !== undefined) {
          if (data.counts.count !== undefined) {
            $('#rp-comments-count')
              .show()
              .children('span').text(data.counts.count);

            commentCount = parseInt(data.counts.count);
          } else {
            $('#rp-comments-count-private')
              .show()
              .children('span').text(data.counts.private);
            $('#rp-comments-count-public')
              .show()
              .children('span').text(data.counts.public);

            commentCount = parseInt(data.counts.private) + parseInt(data.counts.public);
          }

          notSeenCommentsCount = parseInt(data.counts.notSeenCommentsCount);
        } else {
          $('#rp-comments-count')
            .show()
            .children('span').text(commentCount);
        }

        if (commentCount > 0) {
          const $counterElement = $(`.addCommentLink[data-${type}-id=${id}]`);
          if ($counterElement.children('i').length > 0) {
            $counterElement.children('i').addClass('m-r-5');
            $counterElement.children('small').text(commentCount);
          } else if ($counterElement.children('svg').length > 0) {
            if (commentCount > 1) {
              $counterElement.html(`<svg width="18" height="18" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="comments"><polyline fill="none" stroke="currentColor" points="2 0.5 19.5 0.5 19.5 13"></polyline><path fill="currentColor" d="M5,19.71 L5,15 L0,15 L0,2 L18,2 L18,15 L9.71,15 L5,19.71 L5,19.71 L5,19.71 Z M1,14 L6,14 L6,17.29 L9.29,14 L17,14 L17,3 L1,3 L1,14 L1,14 L1,14 Z"></path></svg>&nbsp;${commentCount}`);
            } else {
              $counterElement.html(`<svg width="18" height="18" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="comment"><path fill="currentColor" d="M6,18.71 L6,14 L1,14 L1,1 L19,1 L19,14 L10.71,14 L6,18.71 L6,18.71 Z M2,13 L7,13 L7,16.29 L10.29,13 L18,13 L18,2 L2,2 L2,13 L2,13 Z"></path></svg>&nbsp;${commentCount}`);
            }

            if (notSeenCommentsCount === 0) {
              $counterElement.addClass('all-seen-by-user');
            }
          }
        }

        const addCommentAction = document.getElementById('add-comment-action');
        if (addCommentAction) {
          addCommentAction.scrollIntoView(false);
        }
      })
      .fail(function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR.responseText);
      })
      ;
  },

  submitComment(id, type, privateChannel = false, content = null, file = null) {
    return $.ajax({
      url: Routing.generate('comment_add'),
      type: "POST",
      data: {
        "id": id,
        "type": type,
        "private": privateChannel ? 1 : 0,
        "content": content ? content : null,
        "file": file ? file : null,
      },
    });
  },

  init() {
    if ($('#wysiwygcomment').length) {
      Comment.initWysiwygComment();
    }

    const submitComment = function(content, file, reload) {
      const $sideBar = $('#rp-sidebar');
      const type = $sideBar.data('report-id') ? 'report' : 'task';
      const id = $sideBar.data(`${type}-id`);
      const privateChannel = $('#comments-channel-switch').length > 0 && $('#private-channel').parent().hasClass('uk-active');
      const $addCommentAction = $('#add-comment-action').children('button');
      const $form = $addCommentAction.closest('form');

      if (reload === undefined) {
        reload = true;
      }

      $addCommentAction.prop('disabled', true);
      if (App.$loader.parent() !== $form) {
        $form.append(App.$loader);
      }

      return Comment.submitComment(
        id,
        type,
        privateChannel,
        content,
        file,
      )
        .done(function(data) {
          Helper.checkResponse(data);

          if (!reload) {
            return;
          }

          Comment.reloadComments(id, type, privateChannel);
        })
        .fail(function(xhr) {
          const error = file ? Translator.trans('comments.add-error-file', {file_size: App.maxFileSize / 1024 / 1024}) :  Translator.trans('comments.add-error');
          //@todo show something better
          window.alert(error);
        })
        .always(function () {
          if (!reload) {
            return;
          }

          App.$loader.remove();
          $addCommentAction.prop('disabled', false);
        });
    };

    const submitFiles = function (commentFiles, reload = true) {
      const promises = [];
      commentFiles.forEach((commentFile) => {
        promises.push(Helper.blobToDataURI(commentFile).then(file => submitComment(null, {name: commentFile.name, type: commentFile.type, file: file}, false)));
      });

      if (!reload) {
        return;
      }

      Promise.all(promises).then(results => results).catch(results => results)
        .then(() => {
          Comment.reloadComments();
        });
    };

    $('#add-comment-action button').on('click', function (e) {
      e.preventDefault();

      const content = CKEDITOR.instances.wysiwygcomment.getData();
      let commentFiles = document.getElementById('comment-file').files;

      const promise = content ? submitComment(content, null, commentFiles.length === 0) : $.Deferred().resolve().promise();
      if (commentFiles.length > 0) {
        promise.done(() => submitFiles(commentFiles));
      }

      return false;
    });
  },
};

export default Comment;
