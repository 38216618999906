"use strict";

import UIkit from "uikit";

const Helper = {
  debounce(func, delay) {
    let timerId;
    return (...args) => {
      const boundFunc = func.bind(this, ...args);
      clearTimeout(timerId);
      timerId = setTimeout(boundFunc, delay);
    }
  },

  blobToDataURI(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  },

  // Return date with format dd/mm/YYYY
  formatDate(rawDate) {
    const date = new Date(rawDate);
    const day = (date.getDate() + '').padStart(2, '0');
    const month = ((date.getMonth() + 1) + '').padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  },

  // Return date with format dd/mm/YYYY
  formatDateISO(rawDate) {
    const date = new Date(rawDate);
    const day = (date.getDate() + '').padStart(2, '0');
    const month = ((date.getMonth() + 1) + '').padStart(2, '0');
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  },

  formatTime(rawDate) {
    const date = new Date(rawDate);
    const hour = (date.getHours() + '').padStart(2, '0');
    const minute = (date.getMinutes() + '').padStart(2, '0');

    return `${hour}:${minute}`;
  },

  // Return date from string with format dd/mm/YYYY
  parseDate(date, hours = 0, minutes = 0, seconds = 0) {
    const splitted = date.split('/');

    return new Date(splitted[2], --splitted[1], splitted[0], hours, minutes, seconds);
  },

  monthNames: null,
  getMonthName(monthIndex) {
    if (this.monthNames === null) {
      this.monthNames = [
        Translator.trans('date.month.january'),
        Translator.trans('date.month.february'),
        Translator.trans('date.month.mars'),
        Translator.trans('date.month.april'),
        Translator.trans('date.month.may'),
        Translator.trans('date.month.june'),
        Translator.trans('date.month.july'),
        Translator.trans('date.month.august'),
        Translator.trans('date.month.september'),
        Translator.trans('date.month.october'),
        Translator.trans('date.month.november'),
        Translator.trans('date.month.december'),
      ];
    }

    return this.monthNames[monthIndex];
  },

  formatDateLong(date) {
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return `${day} ${this.getMonthName(monthIndex)} ${year} ${Translator.trans('date.at')} ${this.formatTime(date)}`;
  },

  getModalTemplate(title, body, cancelBtnText, confirmBtnId, confirmBtnText) {
    return `<button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-modal-header">
            <h2 class="uk-modal-title">${title}</h2>
        </div>
        <div class="uk-modal-body">
            ${body}
        </div>
        <div class="uk-modal-footer uk-text-right">
            <button class="uk-button uk-button-default uk-modal-close m-r-5" type="button">${cancelBtnText}</button>
            <button type="button" class="uk-button uk-button-primary" id="${confirmBtnId}">${confirmBtnText}</button>
        </div>`;
  },

  getOffCanvasTemplate(title, body, cancelBtnId, cancelBtnText, confirmBtnId, confirmBtnText, deleteBtnId, deleteBtnText) {
    return `<div class="rp-offcanvas-wide" uk-offcanvas="flip: true; overlay: true;" style="z-index: 1010;">
        <div class="uk-offcanvas-bar">
            <div uk-height-viewport="offset-top: true; offset-bottom: 85px">
                <button class="uk-offcanvas-close" type="button" uk-close></button>
                <h3 id="offcanvas-title" class="uk-text-truncate">${title}</h3>
                <hr/>
                <div id="offcanvas-content">${body}</div>
            </div>
            <div class="row">
                <div class="col-6">
                    <button type="button" id="${deleteBtnId}" class="btn btn-danger"><i class="fa fa-trash m-r-5"></i> ${deleteBtnText}</button>
                </div>
                <div class="col-6 text-right">
                    <button type="button" id="${cancelBtnId}" class="btn btn-default m-r-10">${cancelBtnText}</button>
                    <button type="button" id="${confirmBtnId}" class="btn btn-primary">${confirmBtnText}</button>
                </div>
            </div>
        </div>
    </div>`;
  },

  //https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
  escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  },

  uniqid(prefix = "", moreEntropy = false) {
    const c = Date.now() / 1000;
    let d = c.toString(16).split(".").join("");

    while (d.length < 14) {
      d += "0"
    }

    let e = "";
    if (moreEntropy) {
      e = ".";
      e += Math.round(Math.random() * 100000000);
    }

    return prefix + d + e;
  },

  hexdec(hexString) {
    return parseInt(hexString, 16);
  },

  checkResponse(response) {
    return this.handleResponse(response);
  },

  handleResponse(response, loginSuccessCallback) {
    if (typeof response === 'string') {
      this.checkHtmlForLoginForm(response, loginSuccessCallback);
    }

    if (!response.constructor || response.constructor.name !== 'Response') {
      return;
    }

    if (response.headers.get('Content-Type') === 'application/json') {
      return response.json();
    }

    return response.text().then((html) => {
      this.checkHtmlForLoginForm(html, loginSuccessCallback);

      return html;
    });
  },

  loginModal: null,
  checkHtmlForLoginForm(html, loginSuccessCallback) {
    const $loginForm = $(html).find('#form-login');
    if ($loginForm.length > 0) {
      if (!this.loginModal) {
        this.showLoginForm($loginForm, loginSuccessCallback);
      }

      throw new Error('Session expired');
    }
  },

  showLoginForm($loginForm, loginSuccessCallback) {
    $loginForm.find('.rp-forgotten-password').remove();
    $loginForm.find('.rp-remember-me').remove();
    $loginForm.find('.rp-action-submit').addClass('text-right');
    $loginForm.attr('style', 'padding: 15px;');
    $loginForm.prepend(`<div class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>${Translator.trans('session.expired')}</p></div>`);

    this.loginModal = UIkit.modal.dialog($loginForm[0].outerHTML, { stack: true });
    UIkit.util.once(document, 'hidden', '.uk-modal', () => {
      this.loginModal.$destroy(true);
      this.loginModal = null;
    });

    setTimeout(() => {
      $('#form-login').submit((e) => {
        e.preventDefault();

        const $form = $(e.currentTarget);
        const formData = new FormData($form.get(0));
        $.ajax({
          url: $form.attr('action'),
          type: $form.attr('method'),
          data: formData,
          cache: false,
          contentType: false,
          enctype: 'multipart/form-data',
          processData: false,
        })
          .done((htmlResponse) => {
            this.loginModal.hide();

            const $loginForm = $(htmlResponse).find('#form-login');
            if ($loginForm.length > 0) {
              this.showLoginForm($loginForm, loginSuccessCallback);

              return;
            }

            if (loginSuccessCallback) {
              loginSuccessCallback();
            }
          })
          .fail(() => {
            UIkit.notification(Translator.trans('common.error'), {status:'danger', pos: 'top-right'})
          });
      });
    }, 500);
  }
};

export default Helper;
