"use strict";

require('datatables.net-bs4');
require('datatables.net-fixedcolumns-bs4');
require('datatables.net-responsive-bs4');
require('datatables.net-rowgroup-bs4');
require('./bundle/datatables');

import QueryString from 'qs';

const DataTables = {
  init() {
    const $datatables = $('.datatable-settings');

    if ($datatables.length === 0) {
      return;
    }

    $datatables.each(function () {
      const $this = $(this);

      const config = $this.data('datatable-settings');
      const preloadedData = $this.data('datatable-preloaded-data');
      const groupBy = $this.data('datatable-group-by');
      const overrideOptions = {
        dom: "<'row' <'col-sm-12 dataTables_wrapper_responsive' ftr>><'row' <'col-sm-6'l><'col-sm-6 text-right'pi>>",
        drawCallback: function (settings) {
          //hide pagination when only one page to show
          if (settings._iDisplayStart === 0 && settings._iRecordsTotal === settings._iRecordsDisplay && Object.keys(settings.aIds).length < settings._iDisplayLength) {
            $(settings.nTableWrapper).find('.dataTables_paginate').closest('.row').hide();
          } else {
            $(settings.nTableWrapper).find('.dataTables_paginate').closest('.row').show()
          }

          if (settings._iRecordsTotal === 0) {
            $(settings.nTableWrapper).find('.dataTables_filter').remove();
          }
        }
      };
      const queryParams = QueryString.parse(location.search.substr(1));

      if (queryParams.filters) {
        config.filters = queryParams.filters;
      }

      if (queryParams.organizers) {
        config.organizers = queryParams.organizers;
      }

      if (queryParams.archived) {
        config.archived = queryParams.archived;
      }

      if (preloadedData) {
        config.preloadedData = preloadedData;
      }

      if (groupBy) {
        overrideOptions.rowGroup = {dataSrc: groupBy};
      }

      // Overwrite default dataTable configs ( ref: config/packages/datatables.yaml )
      config.options = {...config.options, ...overrideOptions};

      $this.initDataTables(config);

      if ($this.attr('id') === "datatableTasks") {
        $this.on('draw.dt', function () {
          $(".toggle-task-status").each(function(index) {
            if ($(this).is(':checked')){
              $(this).closest("tr").addClass("is-done");
            }
          });
        });
      }
    });
  },
};

export default DataTables;
