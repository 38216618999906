"use strict";

import Common from './app';
import App from "./common/_app";
import Form from "./common/_form";
import Modal from "./common/_bootstrap_modal";

const Login = {
  ...Common,

  initNewPasswordForm() {
    const $form = $('<form method="post" action="' + Routing.generate('new_password', {newPasswordToken: App.newPasswordToken}) + '"><div class="form-group form-group-default"><div class="controls"><input type="password" name="password" class="form-control" required></div></div><button class="btn btn-primary btn-cons m-t-10" type="submit">' + Translator.trans('new-password.submit-btn') + '</button></form>');
    Modal.show(Translator.trans('new-password.title'), $form);
    Form.initPassword($form.find('input[type=password]'));
  },

  initForgottenPasswordForm() {
    const $forgottenPasswordBtn = $('#forgotten-password-btn');
    if ($forgottenPasswordBtn.length === 0) {
      return;
    }

    $forgottenPasswordBtn.click(() => {
      Modal.show(Translator.trans('forgotten-password.title'), '<form method="post" action="' + Routing.generate('forgotten_password') + '"><div class="form-group form-group-default"><div class="controls"><input type="email" name="email" class="form-control" required></div></div><button class="btn btn-primary btn-cons m-t-10" type="submit">' + Translator.trans('new-password.submit-btn') + '</button></form>');
    });
  },

  init() {
    if (App.newPasswordToken) {
      this.initNewPasswordForm();
    }

    this.initForgottenPasswordForm();
  },
};

$(function() {
  Login.init();
});
