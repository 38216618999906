"use strict";

import Modal from "./_bootstrap_modal";
import App from "./_app";
import Form from "./_form";
import Helper from "./_helpers";

const User = {
  initAddNewUserDropdown(elements) {
    if (!elements) {
      elements = document.querySelectorAll('[data-add-new-url]');
    }

    elements.forEach((element) => {
      const $element = $(element);

      $element.change((e) => {
        if ($element.val().indexOf('new') === -1) {
          return true;
        }

        const title = $element.data('add-new-title');
        Modal.show(title, App.$loader);

        const addNewUserDropdownCancelled = () => {
          this.removeNewUserFromSelectedList($element);
          Modal.$modal.off('hidden.bs.modal', addNewUserDropdownCancelled);
        };

        Modal.$modal.on('hidden.bs.modal', addNewUserDropdownCancelled);

        const headers = {'Accept': 'application/json'};
        const url = $element.data('add-new-url');
        fetch(url, {
          method: 'GET',
          headers: headers
        })
          .then(response => Helper.handleResponse(response))
          .then((data) => {
            this.showAddNewUserFormModal(url, headers, title, data.form, $element);
          });
      });

      $element.on('select2:open', (e) => {
        if ($(e.target).children('option[value=new]').length === 0) {
          return;
        }

        $('.select2-container .select2-dropdown:visible').css('position', 'relative').css('padding-bottom', '40px');
      });
    });
  },

  removeNewUserFromSelectedList($element) {
    const index = $element.val().indexOf('new');
    if (index !== -1) {
      if (Array.isArray($element.val())) {
        const values = $element.val();
        values.splice(index, 1);
        $element.val(values);
      } else {
        $element.val($element.children('option').first().val());
      }

      $element.trigger('change');
    }
  },

  showAddNewUserFormModal(url, headers, title, form, $element) {
    const $form = Modal.show(title, form, false).find('form');
    Form.initPassword($form.find('input[type=password]'));
    this.initUserNewCompanyForm($form.find('#contact_newCompany'), $form.find('#contact_company'));

    $form.submit((e) => {
      e.preventDefault();
      const data = new URLSearchParams(new FormData(e.target));

      Modal.show(title, App.$loader, false);
      fetch(url, {
        method: 'POST',
        headers: headers,
        body: data
      })
        .then(response => Helper.handleResponse(response))
        .then((data) => {
          //in case of error, form is returned
          if (data.form !== undefined) {
            this.showAddNewUserFormModal(url, headers, title, data.form, $element);

            return;
          }

          if ($element.data('type') !== undefined) {
            const type = $element.data('type');
            $(`select.${type}`).filter(':not(#' + $element.attr('id') + ')').each(function () {
              $(this).children('option').last().before(new Option(`${data.new.text} (${Translator.trans(`roles.${type}`, {client: App.label.client_singular, contractor: App.label.contractor_singular})})`, data.new.id, false, false));
            });
          }

          $element.children('option').last().before(new Option(data.new.text, data.new.id, false, true));
          this.removeNewUserFromSelectedList($element);

          Modal.$modal.modal('hide');
        })
        .catch((e) => {
          alert('An error happen, please try again.');
          Modal.$modal.modal('hide');
        });

      return false;
    });
  },

  initUserNewCompanyForm($newCompanyForm, $userCompanySelect) {
    $newCompanyForm = $newCompanyForm === undefined ? $('#contact_newCompany') : $newCompanyForm;

    if ($newCompanyForm.length === 0) {
      return;
    }

    $userCompanySelect = $userCompanySelect === undefined ? $('#contact_company') : $userCompanySelect;

    $userCompanySelect.change((e) => {
      const $this = $(e.target);

      if ($this.val() === 'new') {
        $this.val(null);
        $newCompanyForm.slideDown();
        $newCompanyForm.closest('fieldset').prop('disabled', false);
      } else {
        $newCompanyForm.slideUp();
        $newCompanyForm.closest('fieldset').prop('disabled', true);
      }
    }).trigger('change');

    Form.initSelect2($userCompanySelect);

    $userCompanySelect.on('select2:open', (e) => {
      if ($(e.target).children('option[value=new]').length === 0) {
        return;
      }

      $('.select2-container .select2-dropdown:visible').css('position', 'relative').css('padding-bottom', '40px');
    });
  },

  init() {
    this.initAddNewUserDropdown();
    this.initUserNewCompanyForm();
  },
};

export default User;
