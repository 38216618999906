"use strict";

import Helper from "./_helpers";
import UIkit from "uikit";
import App from "./_app";
import Comment from "./_comments";
import Form from "./_form";

const Task = {
  initTaskStatusToggle() {
    const $table = $('.tasks');
    if ($table.length === 0) {
      return;
    }

    $table.on('click', '.toggle-task-status', (e) => {
      if (e.target.tagName === 'A') {
        e.preventDefault();
      }

      const $checkbox = $(e.target);
      const url = $checkbox.data('href');

      const $li = $checkbox.closest('li.rp-project-task');
      if ($li.length > 0) {
        if ($checkbox.hasClass('fa-circle-o')) {
          $checkbox.removeClass('fa-circle-o');
          $checkbox.addClass('fa-check-circle');
        } else {
          $checkbox.addClass('fa-circle-o');
          $checkbox.removeClass('fa-check-circle');
        }
      }

      //@todo handle error
      fetch(url, {method: 'PATCH'})
        .then((response) => {
          if (!response.ok) {
            throw 'An error happened';
          }

          if ($li.length > 0) {
            return Helper.handleResponse(response);
          }

          const $tr = $checkbox.closest('tr');
          if ($tr.length > 0) {
            if ($checkbox.is(':checked')) {
              $tr.addClass('is-done');
            } else {
              $tr.removeClass('is-done');
            }

            $tr.find('.task-end-date')
              .text($checkbox.is(':checked') ? Helper.formatDate(new Date()) : '');

            if ( $checkbox.is(':checked') ){
              $checkbox.closest('tr').addClass("is-done");
            } else {
              $checkbox.closest('tr').removeClass("is-done");
            }
          }

          document.dispatchEvent(new Event('reloadTasksList'));
        })
        .then((data) => {
          if ($li.length > 0) {
            const $card = $li.closest('.card');

            $li.find('.rp-task-date').text(data.newDate);
            $card.find('.rp-job-percent').text(data.newPercentage + '%');
            $card.find('.progress-bar').attr('style', `width: ${data.newPercentage}%`);
          }
        })
        .catch(() => alert('An error happened'));
    });
  },

  initTaskModal() {
    let ajaxTaskFormOffCanvas = null;
    let loaderDialog = null;

    const showAjaxTaskForm = (data, route, headers) => {
      const deleteBtnId = 'ajaxTaskDeleteBtn';
      const cancelBtnId = 'ajaxTaskCancelBtn';
      const confirmBtnId = 'ajaxTaskSubmitFormBtn';

      const $offCanvas = $(Helper.getOffCanvasTemplate(data.title || Translator.trans('task.new.title'), $(data.form).append(`<button class="uk-hidden" id="${confirmBtnId}-target"></button>`)[0].outerHTML, cancelBtnId, Translator.trans('task.new.cancel-btn'), confirmBtnId, Translator.trans('task.new.confirm-btn'), deleteBtnId, Translator.trans('task.new.delete-btn')));
      $('body').append($offCanvas);

      ajaxTaskFormOffCanvas = UIkit.offcanvas($offCanvas[0]);

      $(`#${confirmBtnId}`).click((e) => {
        e.preventDefault();

        $(`#${confirmBtnId}-target`).click();
      });

      $(`#${cancelBtnId}`).click((e) => {
        e.preventDefault();

        ajaxTaskFormOffCanvas.hide();
      });

      $(`#${deleteBtnId}`).hide();
      // $(`#${deleteBtnId}`).click((e) => {
      //   e.preventDefault();
      //
      //   //@todo delete tasks
      // });

      const $form = $(ajaxTaskFormOffCanvas.$el).find('form');
      $form.attr('action', route);

      this.initTaskForm();

      $form.submit((e) => {
        e.preventDefault();

        const bodyData = new URLSearchParams(new FormData(e.target));

        if (ajaxTaskFormOffCanvas) {
          ajaxTaskFormOffCanvas.$destroy(true);
        }

        loaderDialog = UIkit.modal.dialog(App.$loader[0].outerHTML);
        fetch(route, {
          method: 'POST',
          headers: headers,
          body: bodyData
        })
          .then(response => Helper.handleResponse(response))
          .then((responseData) => {
            loaderDialog.$destroy(true);

            //in case of error, form is returned
            if (responseData.form !== undefined) {
              showAjaxTaskForm(responseData, route, headers);

              return;
            }

            if ($('#rp-sidebar').hasClass('is-open')) {
              Comment.reloadComments();
            } else if ($('.task-organizer').length > 0) {
              $('.task-organizer').first().trigger('change');
            } else if (location.pathname.indexOf('reports') === -1) {
              location.reload();
            }

            document.dispatchEvent(new Event('reloadTasksList'));
          })
          .catch((e) => {
            loaderDialog.$destroy(true);

            window.alert('An error happen, please try again.');
          });

        return false;
      });

      ajaxTaskFormOffCanvas.show();
    }

    $(document).on('click', '.ajax-task-btn', (e) => {
      e.preventDefault();

      if (ajaxTaskFormOffCanvas) {
        ajaxTaskFormOffCanvas.$destroy(true);
      }

      if (loaderDialog) {
        loaderDialog.$destroy(true);
      }

      const $clickedBtn = $(e.currentTarget);
      loaderDialog = UIkit.modal.dialog(App.$loader[0].outerHTML);

      const routeParams = {};
      ['project', 'assignedTo', 'comment', 'id'].forEach((data) => {
        if ($clickedBtn.data(data)) {
          routeParams[data] = $clickedBtn.data(data);
        }
      });

      const route = Routing.generate($clickedBtn.data('route') || 'task_new', routeParams);
      const headers = {
        'Accept': 'application/json',
      };

      fetch(
        route,
        {
          method: 'GET',
          headers: headers,
        }
      )
        .then(response => Helper.handleResponse(response))
        .then(data => {
          loaderDialog.$destroy(true);

          showAjaxTaskForm(data, route, headers);
        })
        .catch((e) => {
          loaderDialog.$destroy(true);

          window.alert('An error happen, please try again.');
        });
    });
  },

  initTaskForm() {
    const $project = $('#task_project');

    if ($project.length === 0) {
      return;
    }

    $project.change((e) => {
      const $form = $(e.target).closest('form');
      // Simulate form data, but only include the selected sport value.
      const data = {};
      data[$project.attr('name')] = $project.val();
      // Submit data via AJAX to the form's action path.
      $.ajax({
        url: $form.attr('action'),
        type: $form.attr('method'),
        data: data,
        success: (html) => {
          Helper.checkResponse(html);
          // Replace current field ...
          const $taskAssignedTo = $('#task_assignedTo');
          if ($taskAssignedTo.data('select2')) {
            $taskAssignedTo.select2('destroy');
          }

          $taskAssignedTo.replaceWith(
            // ... with the returned one from the AJAX response.
            $(html).find('#task_assignedTo')
          );

          Form.initSelect2($('#task_assignedTo'));
        },
      });
    });

    if (!$project.data('select2')) {
      Form.initSelect2($project);
    }

    const $priority = $('#task_priority');
    if (!$priority.data('select2')) {
      Form.initSelect2($priority);
    }

    const $assignedto = $('#task_assignedTo');
    if (!$assignedto.val()) {
      $project.trigger('change');
    } else if (!$assignedto.data('select2')) {
      Form.initSelect2($assignedto);
    }

    $project.closest('form').submit(function () {
      $project.prop('disabled', false);
    });

    Form.initDate($('#task_expectedEndDate'));
  },

  init() {
    this.initTaskModal();
    this.initTaskForm();
    this.initTaskStatusToggle();
  },
};

export default Task;
