"use strict";

import UIkit from "uikit";
import Helper from "./_helpers";
import App from "./_app";

const Feedback = {
  init() {
    const $feedbackFormComment = $('#feedback-form-comment');
    const $feedbackFormFile = $('#feedback-form-file');
    const $feedbackFormSubmitBtn = $('#feedback-form-submit-btn');
    const errors = [];

    window.onerror = function (msg, url, lineNo, columnNo, error) {
      errors.push({
        msg: msg,
        url: url,
        lineNo: lineNo,
        columnNo: columnNo,
        error: error,
      });

      return false;
    };

    const clearFeedback = () => {
      $feedbackFormComment.val('');
      $feedbackFormFile.val('');
      errors.splice(0, errors.length);
      UIkit.modal(document.getElementById('feedback')).hide();
    };

    const submitData = (data) => {
      return fetch(Routing.generate('feedback'), {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok && response.status === 202) {
            clearFeedback();
            return;
          }


          Helper.handleResponse(response).then(() => {
            alert('something went terribly wrong, please try again');
          });
        })
        .catch(() => {
          alert('something went terribly wrong, please try again');
        })
        .finally(() => {
          $feedbackFormSubmitBtn.prop('disabled', false);
          App.$loader.remove();
        });
    };

    $('#feedback-form').submit((e) => {
      e.preventDefault();

      $feedbackFormSubmitBtn.prop('disabled', true);
      $(e.target).append(App.$loader);

      const data = {
        comment: $feedbackFormComment.val(),
        url: location.href,
        errors: errors,
      };

      const files = $feedbackFormFile.get(0).files;

      if (files.length > 0) {
        Helper.blobToDataURI(files[0]).then((file) => {
          data.file = {
            name: files[0].name,
            content: file.replace(/^data:.+?,/, ''),
          };

          return submitData(data);
        });
      } else {
        submitData(data);
      }

      return false;
    });
  },
};

export default Feedback;
