"use strict";

import UIkit from "uikit";
import Helper from "./_helpers";
import App from "./_app";

const Search = {
  getRouteByType(id, type, extraParams) {
    switch (type) {
      case 'project':
        return Routing.generate('project_details', {project: id, ...extraParams});
      case 'contact':
        return Routing.generate('contact_index', {user: id, ...extraParams});
      case 'manager':
        return Routing.generate('manager_index', {user: id, ...extraParams});
      case 'task':
        return Routing.generate('task_show', {id: id, ...extraParams});
      case 'document':
        return Routing.generate('project_documents', {project: id, ...extraParams});
      case 'report':
        return Routing.generate('report_show', {id: id, ...extraParams});
      case 'comment':
        return Routing.generate('show_comment', {id: id, ...extraParams});
    }

    return null;
  },

  initGlobalSearch() {
    const $searchContainer = $('#search-modal-full');
    const $search = $('#search');
    const $filtersContainer = $('#search-results-filters');
    const $resultsContainer = $('#search-results');
    let previousSearch = null;

    let debounce = null;
    const clearSearch = (clearPreviousSearch = false) => {
      if (debounce) {
        clearTimeout(debounce);
      }

      if (clearPreviousSearch) {
        previousSearch = null;
      }

      $filtersContainer.empty();
      $resultsContainer.empty();
    };

    const search = () => {
      const searchValue = $search.val();
      previousSearch = searchValue;

      fetch(Routing.generate('search'), {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({search: searchValue}),
      })
        .then(response => Helper.handleResponse(response, search))
        .then(data => {
          const sections = Object.keys(data);
          const searchRegexp = new RegExp(`(${searchValue})`, 'gi');
          const searchReplace = '<em>$1</em>';

          if (sections.length > 0) {
            $filtersContainer.append(`<li class="uk-active"><a href="javascript:void(0)" class="search-filter" data-filter="all">${Translator.trans(`search.section.all`)}</a></li>`);

            sections.forEach((section, i) => {
              $filtersContainer.append(`<li><a href="javascript:void(0)" class="search-filter" data-filter="${section}">${Translator.trans(`search.section.${section}`)}</a></li>`);
              const $section = $(`<div id="search-section-${section}" class="search-section"><h3>${Translator.trans(`search.section.${section}`)}</h3><ul></ul></div>`);
              const $sectionResults = $section.children('ul');

              data[section].forEach((result) => {
                const $result = $(`<li><a href="${this.getRouteByType(result.type === 'manager' ? result.userId : result.id, result.type, result.extraRouteParams || {})}">${result.name.replace(searchRegexp, searchReplace)}</a></li>`);

                if (result.matches.length > 0) {
                  const $resultMatches = $('<ul></ul>');
                  result.matches.forEach((match) => {
                    if (match.extraRouteParams) {
                      $resultMatches.append(`<li><a href="${this.getRouteByType(result.type === 'manager' ? result.userId : result.id, result.type, match.extraRouteParams)}"><small>${match.text.replace(searchRegexp, searchReplace)}</small></a></li>`)
                    } else {
                      $resultMatches.append(`<li><small>${match.text.replace(searchRegexp, searchReplace)}</small></li>`)
                    }
                  });

                  $result.append($resultMatches);
                }

                $sectionResults.append($result)
              });
              $resultsContainer.append($section);
            });

            $('.search-filter[data-filter=all]').click();
          } else {
            $resultsContainer.append(Translator.trans('search.no-result'))
          }
        })
        .catch((e) => {
          window.alert(Translator.trans('search.error'));
          console.log(e);
        })
        .then(() => App.$loader.removeClass('uk-width-1-1').remove());
    };

    const triggerSearch = (withDebounce = true, clearPreviousSearch = false) => {
      if (previousSearch === $search.val()) {
        return;
      }

      clearSearch(clearPreviousSearch);

      if ($search.val().length <= 3) {
        if (App.$loader.is(':visible')) {
          App.$loader.removeClass('uk-width-1-1').remove();
        }

        return;
      }

      if (!App.$loader.is(':visible')) {
        $resultsContainer.parent().append(App.$loader.addClass('uk-width-1-1'));
      }

      if (!withDebounce) {
        search();

        return;
      }

      debounce = setTimeout(() => search(), 500);
    };

    window.addEventListener('keydown', (e) => {
      if (e.defaultPrevented) {
        return; // Should do nothing if the default action has been cancelled
      }

      const isCtrlKey = navigator.platform.indexOf('Mac') > -1 ? e.metaKey : e.ctrlKey
      let handled = false;

      if ((e.key !== undefined && (e.key === 'F3' || (isCtrlKey && e.key === 'f'))) || (e.keyCode !== undefined && (e.keyCode === 114 || (isCtrlKey && e.keyCode === 70)))) {
        UIkit.modal("#search-modal-full").show();
        handled = true;
      }

      if (((e.key !== undefined && e.key === 'Enter') || (e.keyCode !== undefined && e.keyCode === 13)) && $search.is(':focus')) {
        triggerSearch(false, true);
        handled = true;
      }

      if (handled) {
        // Suppress "double action" if event handled
        e.preventDefault();
      }
    }, true);

    $searchContainer.on('beforeshow', () => {
      const searchField = document.getElementById('search');
      searchField.value = '';

      clearSearch(true);
    });

    $searchContainer.on('shown', () => document.getElementById('search').focus());

    $search.keyup(() => triggerSearch());

    $(document).on('click', '.search-filter', (e) => {
      const $filter = $(e.currentTarget);
      const filter = $filter.data('filter');
      const $sections = $('.search-section');

      if (filter === 'all') {
        $sections.addClass('uk-active');

        return;
      }

      $sections.removeClass('uk-active');
      $(`#search-section-${filter}`).addClass('uk-active');
    });
  },

  initProjectSearch() {
    const $projectGrid = $('.rp-projects-grid > div.uk-grid > div');
    const $projectList = $('#project-list-dropdown > .dropdown-item');

    if ($projectGrid.length > 0) {
      $('#project-grid-search').keyup(function(e) {
        const search = e.currentTarget.value.toLowerCase();

        $projectGrid.each(function() {
          const $this = $(this);

          if (search.length === 0 || $this.text().toLowerCase().indexOf(search) !== -1) {
            $this.fadeIn();
          } else {
            $this.fadeOut();
          }
        });
      });
    }

    if ($projectList.length > 0) {
      const $projectListSearch = $('#project-list-search');
      $projectListSearch.keyup(function(e) {
        const search = e.currentTarget.value.toLowerCase();

        $projectList.each(function() {
          const $this = $(this);

          if (search.length === 0 || $this.text().toLowerCase().indexOf(search) !== -1) {
            $this.fadeIn();
          } else {
            $this.fadeOut();
          }
        });
      });

      $('#project-list-dropdown').parent()
        .on('shown.bs.dropdown', () => $projectListSearch.focus())
        .on('hidden.bs.dropdown', () => $projectListSearch.val('') && $projectList.show());
    }
  },

  initDataTablesGlobalSearch() {
    $('#global-table-filter').keyup(Helper.debounce((e) => {
      const search = e.currentTarget.value;
      $('.dataTables_filter')
        .find('input[type=search]')
        .each(function() {
          $(this).val(search).keyup();
        });
    }, 400));
  },

  init() {
    this.initGlobalSearch();
    this.initProjectSearch();
    this.initDataTablesGlobalSearch();
  },
};

export default Search;
