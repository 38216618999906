"use strict";

import UIkit from "uikit";
import Helper from "./_helpers";
import App from "./_app";
import Form from "./_form";

const Prestation = {
  initPrestationModal() {
    const $ajaxPrestationFormDropdown = $('#prestation-timer-dropdown');
    let ajaxPrestationFormModal = null;
    let loaderDialog = null;

    const showAjaxPrestationForm = (data, route, headers, formName = 'prestation', lastPrestation = null) => {
      const isDropdown = $ajaxPrestationFormDropdown.length > 0 && formName === 'prestation_light';
      const confirmBtnId = 'ajaxPrestationSubmitFormBtn' + (isDropdown ? 'Dropdown' : '');
      const dataForm = $(data.form).append(`<button class="uk-hidden" id="${confirmBtnId}-target"></button>`)[0].outerHTML;

      if (isDropdown) {
        $ajaxPrestationFormDropdown.empty().append(dataForm).prepend(`<div class="rp-timetracker-dropdown--title">${Translator.trans('prestation.new.dropdown.title')}</div>`).prepend(`<div class="rp-timetracker-dropdown--btn-close"><a href="#" id="prestation-timer-dropdown-close-btn" uk-tooltip class="btn btn-xs" title="${Translator.trans('prestation.new.cancel-btn')}"><i class="pg-close"></i></a></div>`);
        $('.rp-timetracker-dropdown form').append(`<div class="form-group"><label>${Translator.trans('prestation.new.actions-label')}</label></div><div class="uk-grid-collapse rp-actions" uk-grid></div>`);
        $('.rp-timetracker-dropdown .rp-actions').append(`<div class="uk-width-auto" data-rp-btn-id="${confirmBtnId}"><button type="button" class="uk-button uk-button-primary" id="${confirmBtnId}">${Translator.trans('prestation.new.confirm-btn')}</button></div>`);

        $('#prestation-timer-dropdown-close-btn').click(() => UIkit.dropdown($ajaxPrestationFormDropdown[0]).hide(false));
      } else {
        ajaxPrestationFormModal = UIkit.modal.dialog(Helper.getModalTemplate(Translator.trans('prestation.new.title'), dataForm, Translator.trans('prestation.new.cancel-btn'), confirmBtnId, Translator.trans('prestation.new.confirm-btn')));
      }

      const $confirmBtn = $(`#${confirmBtnId}`);
      const $confirmBtnWrapper = $(`[data-rp-btn-id="${confirmBtnId}"]`);
      const $form = isDropdown ? $ajaxPrestationFormDropdown.find('form') : $(ajaxPrestationFormModal.$el).find('form');
      $form.attr('action', route);

      if (lastPrestation) {
        const $startFromLastBtn = $(`<div class="uk-width-auto"><button type="button" class="uk-button uk-button-primary">${Translator.trans('prestation.new.start-from-last-btn')}</button></div><div class="uk-width-expand"><span class="rp-btn-or">${Translator.trans('prestation.new.or')}</span></div>`);
        $confirmBtnWrapper.before($startFromLastBtn);

        $('.rp-timetracker-dropdown .rp-actions').after(`
            <div class="rp-timetracker-dropdown--last-prestation">
                <div class="rp-timetracker-dropdown--last-prestation--title">
                    ${Translator.trans('prestation.new.last-prestation.title')}
                </div>
                <div class="uk-grid-small" uk-grid>
                    <div class="uk-width-expand">
                        <div class="rp-timetracker-dropdown--last-prestation--project-name" uk-tooltip="title: ${lastPrestation.project.name}; delay: 500">
                            <strong>${lastPrestation.project.name}</strong>
                        </div>
                        ${lastPrestation.job ? '<div>' + lastPrestation.job.name + '</div>' : ''}
                        ${lastPrestation.type ? '<div>' + lastPrestation.type.name + '</div>' : ''}
                    </div>
                    <div class="uk-width-auto uk-flex uk-flex-middle">
                        <div>
                            <div class="rp-timetracker-dropdown--last-prestation--time">
                                ${lastPrestation.duration}
                                <small>${Helper.formatTime(lastPrestation.start)} - ${Helper.formatTime(lastPrestation.end)}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>`);

        $startFromLastBtn.click((e) => {
          e.preventDefault();

          const endDate = new Date(lastPrestation.end);
          if (endDate > new Date()) {
            UIkit.modal.alert(Translator.trans('prestation.errors.time-traveler'));
            return;
          }

          $form.attr('action', route + '/1');

          $(`#${confirmBtnId}-target`).click();
        });
      }

      $confirmBtn.click((e) => {
        e.preventDefault();

        $(`#${confirmBtnId}-target`).click();
      });

      this.initPrestationForm(formName);

      $form.submit((e) => {
        e.preventDefault();

        const bodyData = new URLSearchParams(new FormData(e.target));

        if (ajaxPrestationFormModal) {
          ajaxPrestationFormModal.$destroy(true);
        }

        if (isDropdown) {
          $ajaxPrestationFormDropdown.empty().append(App.$loader);
        } else {
          loaderDialog = UIkit.modal.dialog(App.$loader[0].outerHTML);
        }

        fetch($form.attr('action'), {
          method: 'POST',
          headers: headers,
          body: bodyData
        })
          .then((response) => {
            if (response.status >= 400) {
              const error = new Error();
              error.error = response;
              error.error_code = response.status;

              throw error;
            }

            return Helper.handleResponse(response);
          })
          .then((responseData) => {
            if (loaderDialog) {
              loaderDialog.$destroy(true);
            } else {
              App.$loader.remove();
            }

            //in case of error, form is returned
            if (responseData.form !== undefined) {
              showAjaxPrestationForm(responseData, route, headers, formName);

              return;
            }

            const $timerBtn = $('#prestation-timer');
            if (formName === 'prestation_light') {
              this.startPrestationTimer(responseData.new);

              if (isDropdown) {
                UIkit.dropdown($ajaxPrestationFormDropdown[0]).hide(false)
              }

              return;
            } else if ($timerBtn.data('route') === 'prestation_new' && responseData.new && Helper.formatDate(new Date()) === Helper.formatDate(responseData.new.date)) {
              $timerBtn.data('last-prestation', responseData.new);
            }

            const event = new Event('reloadPrestationTable');
            if (responseData.new) {
              event.newPrestation = responseData.new;
            }
            document.dispatchEvent(event);
          })
          .catch((e) => {
            if (loaderDialog) {
              loaderDialog.$destroy(true);
            } else {
              App.$loader.remove();
            }

            console.log(e);

            window.alert('An error happen, please try again.');
          });

        return false;
      });
    }

    $(document).on('click', '.ajax-prestation-btn', (e) => {
      e.preventDefault();

      const $clickedBtn = $(e.currentTarget);
      const isDropdown = $ajaxPrestationFormDropdown.length > 0 && $clickedBtn.hasClass('prestation-timer');

      if (ajaxPrestationFormModal) {
        ajaxPrestationFormModal.$destroy(true);
      }

      if (loaderDialog) {
        loaderDialog.$destroy(true);
      }

      if (isDropdown) {
        $ajaxPrestationFormDropdown.empty().append(App.$loader);
      } else {
        loaderDialog = UIkit.modal.dialog(App.$loader[0].outerHTML);
      }

      const routeParams = {};
      ['project', 'job', 'type', 'user', 'id', 'timer', 'comment', 'startFromLast', 'startTimer', 'startFrom'].forEach((data) => {
        if ($clickedBtn.data(data.toLowerCase()) !== undefined) {
          routeParams[data] = $clickedBtn.data(data.toLowerCase());
        }
      });

      if (routeParams.startFromLast !== undefined && routeParams.startTimer !== undefined) {
        const lastPrestation = $clickedBtn.data('last-prestation');
        const endDate = new Date(lastPrestation.end);

        if (endDate > new Date()) {
          UIkit.modal.alert(Translator.trans('prestation.errors.time-traveler'));
          return;
        }
      }

      const route = Routing.generate($clickedBtn.data('route') || 'prestation_new', routeParams);
      const headers = {
        'Accept': 'application/json',
      };

      fetch(
        route,
        {
          method: 'GET',
          headers: headers,
        }
      )
        .then(response => {
          if (response.status === 204) {
            return null;
          }

          return Helper.handleResponse(response);
        })
        .then(data => {
          if (loaderDialog) {
            loaderDialog.$destroy(true);
          } else {
            App.$loader.remove();
          }

          if (data.new !== undefined) {
            this.startPrestationTimer(data.new);

            return;
          }

          if ($clickedBtn.hasClass('prestation-timer') && route.indexOf('/stop') !== -1) {
            this.stopPrestationTimer();
            const $timerIcon = $clickedBtn.children('.timetracker-icon').first();
            $timerIcon.attr('src', $timerIcon.attr('src').replace('stop', 'play'));

            $clickedBtn.removeData('start');
            $clickedBtn.removeData('id');
            $clickedBtn.removeData('name');
            $clickedBtn.data('route', 'prestation_new');
            $clickedBtn.data('last-prestation', data.ended);

            document.dispatchEvent(new Event('reloadPrestationTable'));

            if (isDropdown) {
              UIkit.dropdown($ajaxPrestationFormDropdown[0]).hide(false)
            }

            return;
          }

          if (data) {
            showAjaxPrestationForm(data, route, headers, $clickedBtn.hasClass('prestation-timer') ? 'prestation_light' : 'prestation', $clickedBtn.data('last-prestation'));
          }
        })
        .catch((e) => {
          if (loaderDialog) {
            loaderDialog.$destroy(true);
          } else {
            App.$loader.remove();
          }

          console.log(e);

          window.alert('An error happen, please try again.');
        });
    });
  },

  timerInterval: null,
  startPrestationTimer(newPrestation = null) {
    this.stopPrestationTimer();

    const $timer = $('#prestation-timer');
    if ($timer.length === 0) {
      return;
    }

    if (newPrestation !== null) {
      const $timerIcon = $timer.children('.timetracker-icon').first();
      $timerIcon.attr('src', $timerIcon.attr('src').replace('play', 'stop'));

      $timer.data('id', newPrestation.id);
      $timer.data('name', newPrestation.name);
      $timer.data('start', newPrestation.start);
      $timer.data('route', 'prestation_stop');

      document.dispatchEvent(new Event('reloadPrestationTable'));
    }

    if ($timer.data('start') !== undefined) {
      const startDate = new Date($timer.data('start'));
      const updateTimer = () => {
        const seconds = ((new Date).getTime() - startDate.getTime()) / 1000;
        const time = (new Date(null));
        time.setSeconds(seconds);

        $('#prestation-elapsed-time [data-rp-timetracker-label]').attr(`uk-tooltip`, `${$timer.data('name')}`).html(`${$timer.data('name')}`);
        $('#prestation-elapsed-time [data-rp-timetracker-timer]').html(`${time.toISOString().substr(11, 8)}`);
      };

      this.timerInterval = setInterval(updateTimer, 1000);
      updateTimer();
    }
  },

  stopPrestationTimer() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);

      const timetrackerHtmlLabel = $('#prestation-elapsed-time [data-rp-timetracker-label]').attr('data-rp-timetracker-label');
      const timetrackerHtmlTimer = $('#prestation-elapsed-time [data-rp-timetracker-timer]').attr('data-rp-timetracker-timer');
      $('#prestation-elapsed-time [data-rp-timetracker-label]').attr(`uk-tooltip`, timetrackerHtmlLabel).html(timetrackerHtmlLabel);
      $('#prestation-elapsed-time [data-rp-timetracker-timer]').html(timetrackerHtmlTimer);
    }
  },

  initPrestationFormProjectField(formName = 'prestation') {
    const $project = $(`#${formName}_project`);

    if ($project.length === 0) {
      return;
    }

    $project.change((e) => {
      const $form = $(e.target).closest('form');
      // Simulate form data, but only include the selected sport value.
      const data = {};
      data[$project.attr('name')] = $project.val();

      const $user = $(`#${formName}_user`);
      if ($user.length > 0) {
        data[$user.attr('name')] = $user.val();
      }

      // Submit data via AJAX to the form's action path.
      $.ajax({
        url: $form.attr('action'),
        type: $form.attr('method'),
        data: data,
        success: (html) => {
          Helper.checkResponse(html);

          // Replace current field ...
          const $prestationJob = $(`#${formName}_job`);
          if ($prestationJob.data('select2')) {
            $prestationJob.select2('destroy');
          }

          const $prestationType = $(`#${formName}_type`);
          if ($prestationType.data('select2')) {
            $prestationType.select2('destroy');
          }

          const $newPrestationJob = $(html).find(`#${formName}_job`);
          $prestationJob.replaceWith(
            // ... with the returned one from the AJAX response.
            $newPrestationJob
          );

          Form.initSelect2($newPrestationJob);

          const $newPrestationType = $(html).find(`#${formName}_type`);
          $prestationType.replaceWith(
            // ... with the returned one from the AJAX response.
            $newPrestationType
          );

          Form.initSelect2($newPrestationType);
        },
      });
    });

    if (!$project.data('select2')) {
      Form.initSelect2($project);
    }
  },

  initPrestationForm(formName = 'prestation') {
    const $project = $(`#${formName}_project`);

    if ($project.length === 0) {
      return;
    }

    this.initPrestationFormProjectField(formName);

    const $user = $(`#${formName}_user`);
    if ($user.length > 0 && !$user.data('select2')) {
      $user.change((e) => {
        const $form = $(e.target).closest('form');
        // Simulate form data, but only include the selected sport value.
        const data = {};
        data[$user.attr('name')] = $user.val();
        // Submit data via AJAX to the form's action path.
        $.ajax({
          url: $form.attr('action'),
          type: $form.attr('method'),
          data: data,
          success: (html) => {
            // Replace current field ...
            const $prestationProject = $(`#${formName}_project`);
            if ($prestationProject.data('select2')) {
              $prestationProject.select2('destroy');
            }

            const $newPrestationProject = $(html).find(`#${formName}_project`);
            $prestationProject.replaceWith(
              // ... with the returned one from the AJAX response.
              $newPrestationProject
            );

            this.initPrestationFormProjectField(formName);
          },
        });
      });

      Form.initSelect2($user);
    }

    const $job = $(`#${formName}_job`);
    const $type = $(`#${formName}_type`);

    if (!$job.val() && !$type.val()) {
      $project.trigger('change');
    } else {
      Form.initSelect2($job);
      Form.initSelect2($type);
    }

    Form.initDate($(`#${formName}_date`), {daysOfWeekDisabled: App.hasTimeTrackerWeekend ? [] : [0,6]});

    const $times = $('.prestation-time > select');
    if ($times.length > 0) {
      const $startHour = $('#prestation_start_hour');
      const $startMinute = $('#prestation_start_minute');
      const $endHour = $('#prestation_end_hour');
      const $endMinute = $('#prestation_end_minute');

      $times.change((e) => {
        const $currentTarget = $(e.currentTarget);

        const wrongHours = parseInt($startHour.val()) > parseInt($endHour.val());
        if ($currentTarget.is($startHour) && wrongHours) {
          $endHour.val($startHour.val());
        } else if ($currentTarget.is($endHour) && wrongHours) {
          $startHour.val($endHour.val());
        }

        $times.children('option').prop('disabled', false);

        const startHour = parseInt($startHour.val());
        const endHour = parseInt($endHour.val());
        $startHour.children('option').filter((i, option) => parseInt(option.value) > endHour).prop('disabled', true);
        $endHour.children('option').filter((i, option) => parseInt(option.value) < startHour).prop('disabled', true);

        if (startHour === endHour) {
          const wrongMinutes = parseInt($startMinute.val()) > parseInt($endMinute.val());
          if ($currentTarget.is($startMinute) && wrongMinutes) {
            $endMinute.val($startMinute.val());
          } else if ($currentTarget.is($endMinute) && wrongMinutes) {
            $startMinute.val($endMinute.val());
          }

          const startMinute = parseInt($startMinute.val());
          const endMinute = parseInt($endMinute.val());
          if (endMinute > 0) {
            $startMinute.children('option').filter((i, option) => parseInt(option.value) > endMinute).prop('disabled', true);
          }

          $endMinute.children('option').filter((i, option) => parseInt(option.value) < startMinute).prop('disabled', true);
        }
      });
    }
  },

  initLogoutAlertIfTimerIsRunning() {
    const $prestationTimer = $('#prestation-timer');

    if ($prestationTimer.length === 0) {
      return;
    }

    $('#logout-btn').click((e) => {
      if ($prestationTimer.data('id')) {
        e.preventDefault();

        UIkit.modal.confirm(Translator.trans('prestation.logout-alert')).then(() => window.location = e.currentTarget.href, () => null);
      }
    });
  },

  init() {
    this.initPrestationForm();
    this.initPrestationModal();
    this.startPrestationTimer();
    this.initLogoutAlertIfTimerIsRunning();
  },
};

export default Prestation;
