"use strict";

const Modal = {
  $modal: null,
  show(title, message, show) {
    if (this.$modal === null) {
      const modal = '<div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">' +
        '<div class="modal-dialog modal-lg" role="document">' +
        '<div class="modal-content">' +
        '<div class="modal-header">' +
        '<button type="button" class="close" data-dismiss="modal" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' +
        '<h5 class="modal-title" id="modal-title"></h5>' +
        '</div>' +
        '<div class="modal-body" id="modal-text">' +
        '</div>' +
        '</div>' +
        '</div>' +
        '</div>';

      this.$modal = $(modal);
    }

    this.$modal.find('#modal-title').html(title);
    this.$modal.find('#modal-text').html(message);

    if (show !== false) {
      this.$modal.modal();
    }

    return this.$modal;
  },
};

export default Modal;
